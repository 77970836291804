export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.1.0',
    name: 'com-mgmclub-mgmlojistas',
    versionDate: '2024-07-05T09:49:57.813Z',
    description: 'App dos Lojistas MGMClub',
    gitCommitHash: 'gfced313',
    gitCommitDate: '2024-07-05T09:49:57.000Z',
    versionLong: '1.1.0-gfced313',
    gitTag: 'v1.1.0',
};
export default versions;
